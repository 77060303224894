import React from 'react'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import * as sharedStyles from '../styles/sharedLayout.module.css'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import { composeClasses, formatDateWithDayJs, jsonId } from 'utils'
import HelmetExport from 'react-helmet'
import * as BlogStyles from 'styles/blog.module.css'
import * as CategoryDetailStyles from 'styles/blogCategoryDetail.module.css'
import parse from 'html-react-parser'
import Pagination from 'components/pagination'

export const query = graphql`
  query($id: ID!) {
    wordPress {
      category(id: $id) {
        name
        count
        posts(where: { status: PUBLISH }) {
          edges {
            node {
              date
              excerpt
              slug
              title
              author {
                node {
                  name
                }
              }
              featuredImage {
                node {
                  sourceUrl
                }
              }
              postFieldGroup {
                minuteRead
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const BlogCategoryDetail = ({ data, pageContext }) => {
  const BlogDetail = data.wordPress.category
  const BlogPostData = BlogDetail.posts.edges
  const siteUrl = data.site.siteMetadata.siteUrl
  const contextSlug = pageContext.slug
  const contextName = BlogDetail.name
  const {
    breadcrumb: { crumbs }
  } = pageContext

  const BlogPostList = ({ BlogPostData = [] }) => {
    return (
      BlogPostData.length > 0 &&
      BlogPostData.map((item, index) => {
        return (
          <div
            className={BlogStyles.cloudNativeModernisationContainer}
            key={index}
          >
            <Link to={`/post/${item.node.slug}`}>
              <h4>{item.node.title}</h4>
              <span className={BlogStyles.postDateStamp}>
                {formatDateWithDayJs(item.node.date)}
              </span>
              <img
                alt='featureImage'
                height={160}
                src={item.node.featuredImage.node.sourceUrl}
                width={336}
              />
              <p>
                {parse(item.node.excerpt.substring(0, 140) + '...')}
              </p>
              <p className={BlogStyles.authorStyle}>
                {item.node.author.node.name} |{' '}
                {item.node.postFieldGroup.minuteRead} Minute Read
              </p>
            </Link>
          </div>
        )
      })
    )
  }

  return (
    <>
      <HelmetExport>
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          'itemListElement': [{
            '@type': 'ListItem',
            'item': siteUrl,
            'name': 'Home',
            'position': 1
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/post`,
            'name': 'Blog',
            'position': 2
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/${contextSlug}`,
            'name': `${contextName}`,
            'position': 3
          }]
        })}
      </HelmetExport>
      <div
        className={composeClasses(
          CategoryDetailStyles.mainContainer,
          sharedStyles.wrapper
        )}
      >
        <div className={sharedStyles.verticalContainer}>
          <div
            className={composeClasses(
              sharedStyles.verticalContainerInner,
              CategoryDetailStyles.verticalBlogInner
            )}
          >
            <Breadcrumb
              crumbLabel={BlogDetail.name}
              crumbs={crumbs}
              crumbSeparator=' > '
            />
          </div>
        </div>
        <div className={CategoryDetailStyles.headerStyle}>
          <h1>{BlogDetail.name}</h1>
          <p>({BlogDetail.count})</p>
        </div>
        <Pagination pageContext={pageContext} />
        <div
          className={composeClasses(
            CategoryDetailStyles.blogSection,
            BlogStyles.cloudNativeSection
          )}
        >
          {BlogPostList({ BlogPostData })}
        </div>
      </div>
    </>
  )
}

BlogCategoryDetail.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default BlogCategoryDetail
