import React from 'react'
import * as PaginationStyles from 'styles/pagination.module.css'
import { Link } from 'gatsby'
import { composeClasses } from 'utils'
import PropTypes from 'prop-types'

const PaginationButton = ({
  buttonLabel,
  isControl = false,
  isDisabled = false,
  isSelected = false,
  path
}) => {
  return (
    <div
      className={
        composeClasses(
          PaginationStyles.button,
          isDisabled ? PaginationStyles.buttonDisabled : '',
          isSelected ? PaginationStyles.buttonSelected : '',
          isControl ? PaginationStyles.buttonSelected : ''
        )
      }>
      <Link to={path}>
        <button disabled={isDisabled}>{buttonLabel}</button>
      </Link>
    </div>
  )
}

const Pagination = ({ pageContext }) => {
  const {
    previousPagePath,
    nextPagePath,
    pageNumber,
    humanPageNumber,
    numberOfPages
  } = pageContext

  //  Make sure paths start with a slash when using the Link component, otherwise the URLs appends as a user navigates i.e. /blog/blog/2 instead of /blog/2
  //  Reference: https://stackoverflow.com/questions/62588464/gatsby-link-is-using-the-current-address-instead-of-using-the-root-address
  const formatPath = (path) => {
    return path && path.charAt(0) !== '/' ? `/${path}` : path
  }

  //  Use the location property and strip off the last slash and page number i.e. /blog/2 reduced to /blog
  const location = pageContext.breadcrumb.location.substr(0, pageContext.breadcrumb.location.lastIndexOf('/'))
  //  `slug` is used as a base URL for the pagination buttons i.e. /blog/[page number]
  const slug = formatPath(pageContext.slug ? pageContext.slug : location)

  const isDisabled = (path) => !path

  const EllipseDisplay = () => {
    return (
      <div className={PaginationStyles.button}>
        <span>...</span>
      </div>
    )
  }

  return (
    <>
      { numberOfPages > 1 && (
        <nav className={PaginationStyles.container}>
          <div className={PaginationStyles.containerInner}>

            { humanPageNumber && numberOfPages && (
              <div className={PaginationStyles.pageLabels}>
                <span>{`Page ${humanPageNumber} of ${numberOfPages}`}</span>
              </div>
            )}

            <PaginationButton
              buttonLabel='&lt;' //  < character
              isControl
              isDisabled={isDisabled(previousPagePath)}
              path={formatPath(previousPagePath)}
            />

            <div className={PaginationStyles.pageNumbers}>
              {/* If there is less than 4 page or less - display all the numbers */}
              { numberOfPages < 5 && [...Array(numberOfPages)].map((_, i) => {
                return (
                  <PaginationButton
                    buttonLabel={(i + 1).toString()}
                    isSelected={pageNumber === i}
                    path={`${slug}${i === 0 ? '' : `/${i + 1}`}`}
                  />
                )
              })}

              {/*
                Display Rules:
                - 1. If there are more than 4 pages - display the numbered buttons as "< 1 2 3 ... [last page number] >"
                - 2. Always display 4 digits as the user is advancing through the numbers i.e. < 1 2 ... 4 ... [last page number]
                - 3. Rule 1 displays once the user reaches the last page
                */}
              { numberOfPages > 4 && (
                <>
                  <PaginationButton
                    buttonLabel='1'
                    isDisabled={humanPageNumber === 1}
                    path={slug}
                  />
                  <PaginationButton
                    buttonLabel='2'
                    isDisabled={humanPageNumber === 2}
                    path={`${slug}/2`}
                  />

                  {/* Rule #1 */}
                  { humanPageNumber < 4 && (
                    <>
                      <PaginationButton
                        buttonLabel='3'
                        isDisabled={humanPageNumber === 3}
                        path={`${slug}/3`}
                      />

                      <EllipseDisplay />

                      <PaginationButton
                        buttonLabel={numberOfPages.toString()}
                        isDisabled={humanPageNumber === numberOfPages}
                        path={`${slug}/${numberOfPages}`}
                      />
                    </>
                  )}

                  {/* Rule #2 */}
                  { humanPageNumber > 3 && humanPageNumber !== numberOfPages && (
                    <>
                      <EllipseDisplay />

                      <PaginationButton
                        buttonLabel={humanPageNumber.toString()}
                        isDisabled={humanPageNumber === pageNumber + 1}
                        path={`${slug}/${humanPageNumber}`}
                      />

                      <EllipseDisplay />

                      <PaginationButton
                        buttonLabel={numberOfPages.toString()}
                        isDisabled={humanPageNumber === numberOfPages}
                        path={`${slug}/${numberOfPages}`}
                      />
                    </>
                  )}

                  {/* Rule #3 */}
                  { humanPageNumber === numberOfPages && (
                    <>
                      <PaginationButton
                        buttonLabel='3'
                        isDisabled={humanPageNumber === 3}
                        path={`${slug}/3`}
                      />

                      <EllipseDisplay />

                      <PaginationButton
                        buttonLabel={numberOfPages.toString()}
                        isDisabled={humanPageNumber === numberOfPages}
                        path={`${slug}/${numberOfPages}`}
                      />
                    </>
                  )}
                </>
              )}
            </div>

            <PaginationButton
              buttonLabel='&gt;' //  > character
              isControl
              isDisabled={isDisabled(nextPagePath)}
              path={formatPath(nextPagePath)}
            />
          </div>
        </nav>
      )}
    </>
  )
}

PaginationButton.propTypes = {
  buttonLabel: PropTypes.string,
  isControl: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  path: PropTypes.string
}

Pagination.propTypes = {
  pageContext: {
    breadcrumb: PropTypes.object,
    humanPageNumber: PropTypes.number,
    limit: PropTypes.number,
    nextPagePath: PropTypes.string,
    numberOfPages: PropTypes.number,
    pageNumber: PropTypes.number,
    previousPagePath: PropTypes.string,
    skip: PropTypes.number
  }
}

export default Pagination
